async function postAPICall(url, options){
    const rawResponse = await fetch(url, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(options)
    });
    const content = await rawResponse.json();
    return content;
}
export default postAPICall;
