import React, { useState, useEffect }  from 'react';
import { uid } from 'rand-token';
import './Home.css';
import {ReactComponent as Logo} from './assets/bestinhere-logo.svg';
import {ReactComponent as FacebookIcon} from './assets/facebook.svg';
import {ReactComponent as TwitterIcon} from './assets/twitter.svg';
import {ReactComponent as ExperienceOne} from './assets/experience_1.svg';
import {ReactComponent as ExperienceTwo} from './assets/experience_2.svg';
import {ReactComponent as RecommendationOne} from './assets/recommendation_1.svg';
import {ReactComponent as RecommendationTwo} from './assets/recommendation_2.svg';
import {ReactComponent as RecommendationThree} from './assets/recommendation_3.svg';
import {ReactComponent as InspireOne} from './assets/inspire_1.svg';
import {ReactComponent as InspireTwo} from './assets/inspire_2.svg';
import {ReactComponent as LoaderWhite} from '../common/assets/loaderWhite.svg';
import {ReactComponent as BestinhereFooterLogo} from './assets/bestinhere_logo.svg';
import {deviceDetect} from 'react-device-detect';
import postAPICall from '../common/hooks/postAPICall';
import {emailRegex} from '../common/utils/emailRegex';
import useLocalStorage from '../common/hooks/useLocalStorage';
import {initGA} from './GA';

const publicIP = require('public-ip');

function Home(){
    const [email, setEmail] = useState("");
    const [deviceIP, setDeviceIP] = useState("");
    const [deviceToken, setDeviceToken] = useLocalStorage("sessionToken","");
    const [deviceInfo, setDeviceInfo] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInvalidError, setIsInvalidError] = useState(false);
    const [isDuplicateError, setIsDuplicateError] = useState(false);

    const generate_token = () => {
        return uid(12);
    }
    const getIP = async () => {
        try {
            const d_ip = await publicIP.v4();
            setDeviceIP(d_ip);
        } catch(e) {
            console.log(e);
        }
    };

    const getNotified = () => { 
        if(email === "" || emailRegex.test(String(email).toLowerCase())===false){
            setIsInvalidError(true);
            setIsDuplicateError(false); 
        } else {
            setIsLoading(true);
            const beta_response = postAPICall(
                process.env.REACT_APP_BETA_SIGNUP,
                {
                    "device_ip": deviceIP,
                    "device_token": deviceToken,
                    "device_info": deviceInfo,
                    "email": email,
            });
            beta_response.then(response=>{
                if(response.success){
                    setTimeout(()=>{ setIsSuccess(true); setIsLoading(false); setIsSubmitted(true);  },1000);
                } else if(!response.success && response.error_msg === "Email already registered") {
                    setTimeout(()=>{ setIsDuplicateError(true); setIsLoading(false); setIsSubmitted(false); }, 1000);
                } 
            }).catch(err=>{
                setTimeout(()=>{ setIsLoading(false); setIsInvalidError(true); setIsSubmitted(false); }, 1000);
            });
        }
    };

    const updateEmail = (e) =>{ setEmail(e.target.value); };

    useEffect(()=>{
        const device_info = deviceDetect();
        setDeviceInfo(JSON.stringify(device_info));
        getIP();
        if(deviceToken === ""){
            const new_token = generate_token();
            setDeviceToken(new_token);
        }
        initGA();

    },[]);


    return (
    <div>
        <div className="bestinhere__header">
            <div className="container">
                <div className="row">
                    <div className="col-xs-3 col-md-4 ">
                        <div className="bestinhere__logo">
                            <a href="/">
                                <Logo/>
                            </a>
                        </div>
                    </div>
                    <div className="col-xs-9 col-md-8 text-right">
                        <div className="bestinhere__social_icons">
                            <span className="bestinhere__spread_twitter">
                                <a href="https://twitter.com/intent/tweet?text=Find%20best%20experiences%20at%20any%20location-%20@bestinhereapp%20&url=https://bestinhere.com" target="_blank" rel="noopener noreferrer">
                                    <TwitterIcon/>
                                </a>
                            </span>
                            <span className="bestinhere__spread_facebook">
                                <a href="https://www.facebook.com/sharer/sharer.php?u=http://besitnhere.com/" target="_blank" rel="noopener noreferrer">
                                    <FacebookIcon/>
                                </a>
                            </span>
                            <span className="bestinhere__spread_word">Spread the word</span>
                        </div>
                   </div>
                </div>
            </div>
        </div>

        <div className="bestinhere__background_wrapper">
            <div className="container bestinhere__main_block">
                <div className="bestinhere__statement_block">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="bestinhere__statement">
                                <span className="bestinhere__slidein bestinhere__slidein_one">Find the best experiences</span> <br/><span className="bestinhere__slidein bestinhere__slidein_two">at any location</span>
                            </div> 
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="bestinhere__sub_statement">
                                With bestinhere you will have recommendations from your network of trusted people anywhere you go. 
                                It helps you organize, plan and share your travel/local experiences.
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="bestinhere__launch_block">
                                <div className="bestinhere__launch_label">
                                    Launching beta soon, be among the first to try !
                                </div>
                                <div className="bestinhere__launch_email">
                                    {!isLoading &&
                                        <span>
                                            {!isSubmitted && 
                                                <span>
                                                    <form onSubmit={getNotified}>
                                                    <div className="bestinhere__launch_input_block" id="bestinhere__launch_input_block">
                                                        <input id="email" value={email} className="bestinhere__input" placeholder="Email address" type="email" name="email" onChange={updateEmail} />
                                                        <div className="bestinhere__error_msg">
                                                            {!isSuccess && !isInvalidError && !isDuplicateError && <span className="text-warning">Your email will be secure with us. We dislike spam as much as you do.</span>}
                                                            {isInvalidError && <span className="text-danger">Please enter a valid email address.</span>}
                                                            {isDuplicateError && <span className="text-danger">Email is already registered.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="bestinhere__launch_btn_block" id="bestinhere__launch_btn_block">
                                                        <div className="bestinhere__launch_btn" id="bestinhere__launch_btn" onClick={getNotified}>
                                                            GET NOTIFIED
                                                        </div>
                                                    </div>
                                                    </form>
                                                </span>
                                            }
                                        </span>
                                    }
                                    {isLoading && <span className="bestinhere__loader"><LoaderWhite/></span>}
                                    {isSuccess && <div className="bestinhere__thanks" id="bestinhere__thanks"><span className="text-success">Thank you {email}, we shall reach out to you soon.</span></div>}
                                </div>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div> 
        </div>
        <div className="container">
            <div className="bestinhere__focus_block">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="bestinhere__focus_item">
                                    <div className="bestinhere__focus_imgs">
                                        <div className="bestinhere__focus_imgs_block">
                                            <div className="bestinhere__imgs_col bestinhere__imgs_left_col">
                                                <div className="bestinhere__img_block">
                                                    <span className="bestinhere__focus_img bestinhere__img_left_bottom" >
                                                        <ExperienceOne/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="bestinhere__imgs_col bestinhere__imgs_right_col">
                                                <div className="bestinhere__img_block">
                                                    <span className="bestinhere__focus_img bestinhere__img_right_bottom">
                                                        <ExperienceTwo/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bestinhere__focus_text">
                                        <div className="bestinhere__focus_item_label">
                                            Experience new places <br/>like a local
                                        </div>
                                        <div className="bestinhere__focus_description">
                                            New to a location and not sure which places to visit? Tired of the same old hangout spots? Bestinhere helps you find interesting places and suggests you things to try out at those places.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bestinhere__focus_item">
                                    <div className="bestinhere__focus_imgs">
                                        <div className="bestinhere__focus_imgs_block"> 
                                            <div className="bestinhere__imgs_col">
                                                <div className="bestinhere__img_block">
                                                    <div className=" bestinhere__img_left_bottom bestinhere__imgs_left_col">
                                                        <span className="bestinhere__focus_img bestinhere__img_margin">
                                                            <RecommendationOne/>
                                                        </span>
                                                        <span className="bestinhere__focus_img">
                                                            <RecommendationTwo/>
                                                        </span>
                                                    </div>  
                                                </div>
                                            </div>
                                            <div className="bestinhere__imgs_col bestinhere__imgs_right_col">
                                                <div className="bestinhere__img_block">
                                                    <span className="bestinhere__focus_img bestinhere__img_right_bottom">
                                                        <RecommendationThree/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bestinhere__focus_text">
                                        <div className="bestinhere__focus_item_label">
                                            Get trusted recommendations <br/>curated for you
                                        </div>
                                        <div className="bestinhere__focus_description">
                                            Discover places and experiences curated for you based on your interests and recommendations from your friends, family, people that inspire you and the brands that you love.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="bestinhere__focus_item">
                                    <div className="bestinhere__focus_imgs">
                                        <div className="bestinhere__focus_imgs_block"> 
                                            <div className="bestinhere__imgs_col bestinhere__imgs_left_col">
                                                <div className="bestinhere__img_block">
                                                    <span className="bestinhere__focus_img bestinhere__img_left_bottom">
                                                        <InspireOne/>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="bestinhere__imgs_col bestinhere__imgs_right_col"> 
                                                <div className="bestinhere__img_block">
                                                    <span className="bestinhere__focus_img bestinhere__img_right_top">
                                                        <InspireTwo/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bestinhere__focus_text">
                                        <div className="bestinhere__focus_item_label">
                                            Save your inspiration, <br/>Organize your trips
                                        </div>
                                        <div className="bestinhere__focus_description">
                                            Use collections to group your inspiration. 
                                            You can add your friends to the collections to make a list of places you would like to visit or things you would love to try. 
                                            Plan your vacations on the go.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>

        <div className="bestinhere__footer">
            <div className="container">
                <div className="bestinhere__contactinfo">
                    <div className="row">
                        <div className="col-xs-12 col-sm-4 col-md-3">
                            <div className="bestinhere__logo_footer">
                                <a href="https://www.bestinhere.com/"><span className="bestinhere__logo_img_footer"><BestinhereFooterLogo/></span></a>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-3 bestinhere__footer_split">
                                    <div className="bestinhere__footer_label bestinhere__caption_font">
                                        Connect
                                    </div>
                                    <div className="bestinhere__social">
                                        <span className="bestinhere__social_link bestinhere__body_font">
                                            <a href="http://medium.com/@bestinhereapp" target="_blank" rel="noopener noreferrer">Blog</a>
                                        </span><br/>
                                        <span className="bestinhere__social_link bestinhere__body_font">
                                            <a href="http://facebook.com/bestinhere" target="_blank" rel="noopener noreferrer">Facebook</a>
                                        </span><br/>
                                        <span className="bestinhere__social_link bestinhere__body_font">
                                            <a href="http://instagram.com/bestinhereapp" target="_blank" rel="noopener noreferrer">Instagram</a>
                                        </span><br/>
                                        <span className="bestinhere__social_link bestinhere__body_font">
                                            <a href="http://twitter.com/bestinhereapp" target="_blank" rel="noopener noreferrer">Twitter</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-4 col-md-3 bestinhere__footer_split">
                            <div className="bestinhere__footer_label">
                                Contact
                            </div>
                            <div className="bestinhere__phone_email">
                                <span className="bestinhere__email bestinhere__company_link">
                                    <a href="mailto:contact@bestinhere.com">contact@bestinhere.com</a>
                                </span>
                            </div>
                        </div>
                    </div> 
                </div>

                <div className="bestinhere__copyright">
                    <div className="row">
                        <div className="col-md-6 col-xs-5">
                            <span className="bestinhere__copyright_text">&copy; Bestinhere 2020</span>
                        </div>
                        <div className="col-md-6 col-xs-7 text-right">
                            <span className="bestinhere__copyright_text">Bestinhere Pvt. Ltd.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Home;



